<template>
    <div class="CustomPage">
        <div class="content">
            <div class="form">
                <div class="item" v-for="(item,index) in createComponent" :key="index" @click="_setComponentMsg(index)">
                    <!--文本框,多行输入框-->
                    <div class="base-row" :style="item.type=='textarea'?'display:block':''">
                        <div class="input-text">{{item.title}}</div>
                        <div class="input-content">{{item.intro?item.intro:'请输入'}}</div>
                    </div>
                </div>
            </div>
            <div style="margin: 15px;">
                <a-button type="primary" class="add-components" block @click="showDrawer">添加组件</a-button>
            </div>
        </div>
        <!--修改模板信息-->
        <div class="content" style="background: #fff;border: none">
            <div style="padding-bottom: 40px;padding-top: 20px">
                <div class="set-row">
                    <span class="row-label">模板名称</span>
                    <a-input placeholder="请输入"/>
                </div>
                <div class="set-row" style="margin-top: 30px;justify-content: end;align-items: end">
                    <span class="row-label">模板图标</span>
                    <div class="template-icon">
                        <a-icon type="pie-chart" style="font-size: 20px;"/>
                    </div>
                </div>
            </div>

            <div style="padding-top: 40px;border-top: 1px solid #ddd;" v-show="setMsg" v-if="createComponent.length!=0">
                <div class="set-row">
                    <span class="row-label">控件名称</span>
                    <a-input placeholder="请输入" v-model="createComponent[setIndex].title"/>
                </div>
                <div class="set-row" style="margin-top: 30px;justify-content: end;align-items: end">
                    <span class="row-label">控件说明</span>
                    <a-input placeholder="请输入" v-model="createComponent[setIndex].intro"/>
                </div>
                <div class="set-row" style="margin-top: 30px;justify-content: end;align-items: end">
                    <a-button type="primary" @click="_removeTemplate">删除</a-button>
                </div>
            </div>
            <div style="margin: 15px;position: absolute;bottom: 0;width: 100%" v-if="createComponent.length!=0">
                <a-button type="primary" class="add-components" block @click="_saveTemplate">保存模板</a-button>
            </div>
        </div>
        <!--预览生成模板-->
        <div class="content">
            <div class="form">
                <div class="item" v-for="(item,index) in templateData">
                    <!--文本框,多行输入框-->
                    <div class="base-row" :style="item.type=='textarea'?'display:block':''">
                        <div class="input-text">{{item.title}}</div>
                        <input class="input-content template-input" :type="item.type" v-if="item.type=='text'||item.type=='number'" :placeholder="item.intro?item.intro:'请输入'"/>
                        <textarea name="" class="input-content template-textarea" v-if="item.type=='textarea'" :placeholder="item.intro?item.intro:'请输入'"></textarea>
                        <a-date-picker class="input-content" v-if="item.type=='date'"/>
                        <a-date-picker class="input-content" format="YYYY-MM-DD HH:mm" showTime v-if="item.type=='dateTime'"/>
                    </div>
                </div>
            </div>
        </div>

        <a-drawer title="组件库" :placement="placement" @close="onClose" :visible="visible" width="300">
            <div class="components-list">
                <div class="tag" v-for="item in componentList" @click="_addComponent(item.type,item.title)">
                    <div><a-icon :type="item.icon" style="color: #4A5159;font-size: 20px"/></div>
                    <div class="icon-text">{{item.title}}</div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    export default {
        name: "CustomPage",
        data(){
            return {
                visible: false,
                setMsg: false,
                placement: 'right',
                componentList: [
                    {
                        icon: 'form',
                        title: '文本',
                        type: 'text'
                    },
                    {
                        icon: 'align-left',
                        title: '多行文本',
                        type: 'textarea'
                    },
                    {
                        icon: 'align-left',
                        title: '数字',
                        type: 'number'
                    },
                    {
                        icon: 'align-left',
                        title: '日期',
                        type: 'date'
                    },
                    {
                        icon: 'align-left',
                        title: '日期+时间',
                        type: 'dateTime'
                    },
                    {
                        icon: 'align-left',
                        title: '单选框',
                        type: 'radio'
                    },
                    {
                        icon: 'align-left',
                        title: '多选框',
                        type: 'checkbox'
                    }
                ],
                createComponent: [],
                setName: '',
                setExplain: '',
                setIndex: 0,
                templateData: ''
            }
        },
        methods: {
            showDrawer() {
                this.visible = true
            },
            onClose() {
                this.visible = false
            },
            _addComponent(type,title){
                this.createComponent.push({type:type,title:title,intro: ''});
            },
            _setComponentMsg(i){
                this.setMsg = true;
                this.setIndex = i;
            },
            _removeTemplate(){
                this.createComponent.splice(this.setIndex,1);
                this.setIndex = 0;
            },
            _saveTemplate(){
                this.templateData = this.createComponent;
            }
        },
    }
</script>

<style scoped lang="less">
    .CustomPage{
        height: 100%;
        .content{
            position: relative;
            display: inline-block;
            width: 375px;
            height: 667px;
            background: #F5F5F5;
            border: 1px solid #EBEBEB;
            vertical-align: top;
            margin-right: 40px;
            .add-components{
                height: 40px;
                font-size: 16px;
            }
            .form{
                .item{
                    cursor: pointer;
                }
                padding-top: 15px;
                .base-row{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 15px;
                    margin-bottom: 15px;
                    background: #fff;
                    border-top: 1px solid #EBEBEB;
                    border-bottom: 1px solid #EBEBEB;
                    .input-text{
                        font-size: 16px;
                        color: #222;
                    }
                    .input-content{
                        font-size: 16px;
                        color: #aaa;
                    }
                    .template-input{
                        border: none;
                        text-align: right;
                        outline: none;
                    }
                    .template-textarea{
                        width: 100%;
                        min-height: 80px;
                        margin-top: 10px;
                        border: none;
                        outline: none;
                        resize: none;
                    }
                }
            }
            .template-icon{
                width: 60px;
                height: 60px;
                line-height:60px;
                border: 1px solid #ddd;
                text-align: center;
                border-radius: 4px
            }
        }
    }
    .components-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .tag{
            width: 72px;
            height: 60px;
            padding-top: 8px;
            margin-left: 10px;
            margin-bottom: 15px;
            text-align: center;
            border: 1px solid #E4E6E9;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            .icon-text{
                color: #4A5159;
                margin-top: 4px;
                font-size: 12px;
            }
        }
    }
    .set-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .row-label{
            white-space: nowrap;
            margin-right: 20px;
        }
    }
</style>